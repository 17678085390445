<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-text-field
                v-model="vat.number"
                label="Vat Number"
                :clearable="clearable"
                :rules="rules"
            />
            <v-autocomplete
                v-model="vat.partner"
                :rules="rules"
                :items="partners"
                :item-text="getFieldText"
                menu-props="auto"
                label="Select Partner"
                hide-details
                prepend-icon="mdi-domain"
                single-line
                :clearable="!readOnly"
                return-object
                deletable-chips
                chips
            />
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="my-2 white--text" large color="#004cff" :disabled="shouldDisableSubmit()" @click="onSubmit">
                Submit
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import vatService from '@/services/vat-service'
    import partnerService from '@/services/partner-service'
    import Alert from '@/components/Alert'

    export default {
        name: 'VatForm',
        components: { Alert },
        props: {
            index: {
                type: Number,
                required: true
            },
            partnerVat: {
                type: Object,
                default: null
            },
            partner: {
                type: Object,
                default: null
            },
            forceMode: {
                type: String,
                default: null
            }
        },
        data: () => {
            return {
                type: null,
                message: null,
                mode: null,
                readOnly: false,
                clearable: true,
                vatId: null,
                vat: {
                    number: '',
                    member: ''
                },
                partners: [],
                rules: [
                    v => !!v || 'Required Field'
                ],
                showAlert: false
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.forceMode) {
                this.mode = this.forceMode
            }
            this.getAllPartners()
            if (this.mode === 'create' && !this.forceMode) {
                return
            }
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.vatId = this.$route.params.vatId
            if (this.partnerVat) {
                this.vat = this.partnerVat
                this.vat.partner = this.partner
                this.vatId = this.vat.id
            } else if (this.vatId) {
                vatService.getVatById(this.vatId).then(resp => {
                    this.vat = resp.data.vat
                })
            }
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return !this.vat.partner || !this.vat.number
            },
            getFieldText(partner) {
                return `${partner.title}`
            },
            onEdit() {
                this.$router.push(`/vat/${this.vatId}/edit`)
            },
            onSubmit() {
                this.prepareVatFields()
                if (this.mode === 'edit') {
                    vatService.editVatById(this.vatId, this.vat).then(() => {
                        if (!this.forceMode) {
                            this.$router.push(`/vats`)
                        }
                        this.$emit('setAlert', 'Vat edit was successfull', 'success')
                    }).catch((error) => {
                        this.$emit('setAlert', error, 'error')
                    })
                } else if (this.mode === 'create') {
                    vatService.createVat(this.vat).then(() => {
                        if (!this.forceMode) {
                            this.$router.push(`/vats`)
                        }
                        this.$emit('setAlert', 'Vat creation was successfull', 'success')
                    }).catch((error) => {
                        this.$emit('setAlert', error, 'error')
                    })
                }
            },
            getAllPartners() {
                if (this.mode !== 'show') {
                    partnerService.getAllPartners().then((res) => {
                        this.partners = res.data.data
                    })
                }
            },
            prepareVatFields() {
                if (this.vat.id) {
                    delete this.vat.id
                }
                if (this.vat.partner) {
                    this.vat.partner = this.vat.partner.id
                }
            }
        }
    }
</script>

<style scoped>
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
