<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-checkbox
                v-model="partner.available"
                label="Available"
            />
            <v-checkbox
                v-model="partner.b2b"
                label="b2b"
            />
            <v-text-field
                v-model="partner.coordinates"
                label="Coordinates"
                :clearable="clearable"
            />
            <v-text-field
                v-model="partner.title"
                :rules="rules"
                label="Title"
                :clearable="clearable"
            />
            <v-text-field
                v-model="partner.address"
                label="Address"
                :clearable="clearable"
            />
            <!-- <v-text-field
                v-model="partner.afm"
                label="Vat Number"
                :clearable="clearable"
            /> -->
            <v-text-field
                v-model="partner.type"
                label="Type"
                :clearable="clearable"
            />
            <v-select
                v-model="partner.category"
                :items="categories"
                label="Category"
                item-text="title"
                item-value="id"
                :clearable="clearable"
            />
            <v-autocomplete
                v-model="partner.dealer"
                :items="dealers"
                name="Dealer"
                :item-text="getDealerText"
                return-object
                label="Dealers"
                clearable
            />
            <div class="description-editor">
                <span class="description-label">Description</span>
                <editor
                    v-model="partner.description"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
            <v-text-field
                v-model="partner.titleEn"
                label="Title in English"
                :clearable="clearable"
            />
            <v-text-field
                v-model="partner.addressEn"
                label="Address in English"
                :clearable="clearable"
            />
            <v-text-field
                v-model="partner.typeEn"
                label="Type in English"
                :clearable="clearable"
            />
            <div class="description-editor">
                <span class="description-label">Description in English</span>
                <editor
                    v-model="partner.descriptionEn"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
            <v-file-input
                v-if="mode !== 'show'"
                v-model="newImage"
                label="Image input"
                filled
                show-size
                prepend-icon="mdi-camera"
                @change="createNewImagePreview"
            />
            <div class="image-input-wrapper">
                <div v-if="previewImage && !previewImage.shouldDelete" class="image-input" :class="{'green-border': !previewImage.id}">
                    <div v-if="mode === 'edit'" class="delete-btn">
                        <v-btn class="mx-2 white--text" small color="red" @click="onImageDelete()">
                            X
                        </v-btn>
                    </div>
                    <v-img v-if="previewImage.url" class="img-preview" :src="previewImage.url" />
                    <v-text-field
                        v-model="previewImage.description"
                        label="Image Description"
                        :clearable="clearable"
                    />
                </div>
            </div>
        </v-form>
        <v-row align="center">
            <v-col cols="12">
                <v-expansion-panels
                    v-for="(vat, index) of partner.vatNumbers"
                    :key="index"
                    class="my-5"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header class="card-title px-4 mt-1">
                            {{ 'Vat - ' + vat.number }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <vat-form
                                :index="index"
                                :partner-vat="vat"
                                :partner="partner"
                                :force-mode="Object.keys(vat).length > 0 ? 'edit' : 'create'"
                                @setAlert="setAlert"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <span class="mt-3 mx-3"> Remove Partner Vat</span>
                    <v-menu
                        :close-on-content-click="true"
                        :nudge-width="200"
                        offset-x
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                color="black"
                                class="mt-3"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon color="white">
                                    mdi-trash-can
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card height="80px" class="py-2">
                            <div class="text">
                                Are you sure?
                            </div>
                            <v-card-actions class="choices">
                                <v-btn class="mx-2" dark small color="#027a00" @click="removePartnerVat(index)">
                                    Yes
                                </v-btn>
                                <v-btn
                                    class="mx-2"
                                    dark
                                    small
                                    color="#ff0000"
                                >
                                    No
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="addVatNumbers">
            <v-col cols="12">
                <span> Add Partner Vat</span>
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="black"
                    @click="addPartnerVat()"
                >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="my-2 white--text" :disabled="shouldDisableSubmit()" large color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import vatService from '@/services/vat-service'
    import imageService from '@/services/image-service'
    import partnerService from '@/services/partner-service'
    import Editor from '@tinymce/tinymce-vue'
    import store from '@/store'
    import VatForm from '@/forms/VatForm'
    import dealerService from '@/services/dealer-service'

    export default {
        name: 'PartnerForm',
        components: { Editor, VatForm },
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                partnerId: null,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN,
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
                imgUrl: null,
                partner: {
                    title: '',
                    description: '',
                    b2b: false,
                    available: false,
                    address: '',
                    coordinates: '',
                    titleEn: '',
                    descriptionEn: '',
                    addressEn: '',
                    type: '',
                    typeEn: '',
                    vat: '',
                    approved: 1,
                    category: null,
                    dealer: null
                },
                editorInit: {
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks fullscreen emoticons',
                        'insertdatetime media table paste help wordcount'
                    ],
                    fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | blocks fontselect fontsizeselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image'
                },
                newImage: null,
                previewImage: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                categories: [],
                addVatNumbers: false,
                dealers: []
            }
        },
        computed: {
            dealerId() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer' && store.getters.currentUser.dealerId
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.getCategories()
            this.fetchAllDealers()
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
                this.editorInit.setup = function(editor) {
                    editor.setMode('readonly')
                }
            } else {
                this.addVatNumbers = true
            }
            this.partnerId = this.$route.params.partnerId
            if (this.partnerId) {
                partnerService.getPartnerById(this.partnerId).then(resp => {
                    this.partner = resp.data.partner
                    this.partner.category = resp.data.partner.category.id
                    this.previewImage = this.partner.image
                    if (this.previewImage) {
                        this.previewImage.url = this.apiUrl + '/uploads/images/' + this.previewImage.imageName
                    }
                })
            }
        },
        methods: {
            getDealerText(dealer) {
                return `${dealer.firstName} ${dealer.lastName}`
            },
            fetchAllDealers() {
                dealerService.getAllDealers().then(resp => {
                    this.dealers = resp.data.data
                })
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            addPartnerVat() {
                this.partner.vatNumbers.push({})
            },
            removePartnerVat(index) {
                vatService.deleteVatById(this.partner.vatNumbers[index].id).then(() => {
                    this.partner.vatNumbers.splice(index, 1)
                    this.setAlert('Vat deletion was successfull', 'success')
                }).catch((error) => {
                    this.setAlert(error.response, 'error')
                })
            },
            getCategories() {
                partnerService.getAllCategories().then((resp) => {
                    this.categories = resp.data.data
                })
            },
            createNewImagePreview() {
                this.previewImage = this.newImage
                this.previewImage.url = URL.createObjectURL(this.newImage)
                this.previewImage.description = null
            },
            async onSubmit() {
                this.prepareIfDealerExists()
                if (this.mode === 'edit') {
                    this.editPartner()
                } else if (this.mode === 'create') {
                    this.createPartner()
                }
                this.deletePartnerImage()
                this.editImageDescriptions()
            },
            deletePartnerImage() {
                if (this.previewImage && this.previewImage.id && this.previewImage.shouldDelete) {
                    imageService.deleteImageById(this.previewImage.id)
                    this.previewImage = null
                }
            },
            prepareIfDealerExists() {
                const dealerId = this.dealerId
                if (dealerId) {
                    this.partner.dealer = dealerId
                    this.partner.approved = false
                } else if (this.partner.dealer) {
                    this.partner.dealer = this.partner.dealer.id
                } else {
                    this.partner.dealer = null
                }
            },
            async createPartner() {
                const response = await partnerService.createPartner(this.partner)
                if (response.data.id) {
                    this.partnerId = response.data.id
                    this.uploadImage()
                }
                this.$router.push(`/partners?page=${this.$route.query.page}`)
            },
            async editPartner() {
                await partnerService.editPartnerById(this.partnerId, this.partner)
                this.uploadImage()
                this.$router.push(`/partners?page=${this.$route.query.page}`)
            },
            shouldDisableSubmit() {
                return !this.partner.title
            },
            onEdit() {
                this.$router.push(`/partners/${this.partnerId}/edit?page=${this.$route.query.page}`)
            },
            editImageDescriptions() {
                if (this.previewImage && this.previewImage.id) {
                    let formData = new FormData()
                    formData.append('partnerId', this.partner)
                    if (this.previewImage.description) {
                        formData.append('description', this.previewImage.description)
                    }
                    imageService.editImageById(this.previewImage.id, formData)
                }
            },
            uploadImage() {
                if (!this.newImage || (this.newImage && this.newImage.id)) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.newImage)
                formData.append('partnerId', this.partnerId)
                if (this.newImage.description) {
                    formData.append('description', this.newImage.description)
                }
                imageService.uploadNewImage(formData)
            },
            onImageDelete() {
                if (this.previewImage.id) {
                    this.previewImage.shouldDelete = true
                    this.previewImage.url = null
                    this.previewImage = { ...this.previewImage }
                } else {
                    this.newImage = null
                }
            }
        }
    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.description-label {
    color: rgba(0, 0, 0, 0.6);
}
.description-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}
.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
