import paronApi from '@/utils/paron-api'

export default {
    getAllVats(page) {
        var path = page ? `vat/all?page=${page}&per_page=10` : 'vat/all'
        return paronApi.get(path)
    },

    getVatById(id) {
        return paronApi.get(`vat/${id}`)
    },

    createVat(data) {
        return paronApi.post('vat/new', data)
    },

    editVatById(id, data) {
        return paronApi.post(`vat/${id}/edit`, data)
    },

    deleteVatById(id) {
        return paronApi.delete(`vat/${id}`)
    }

}
